import { Card, CardContent, Typography, Grid } from "@material-ui/core";
import { COMPONENT_WIDTH } from "../constants/constants";

export default function CardComponent({ title, content }) {
  return (
    <Card sx={{ maxWidth: COMPONENT_WIDTH.CUSTOM_CARD_MAX_WIDTH }} style={{ margin: "1rem 0" }} variant="outlined">
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          {title}
        </Typography>
        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
          <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={2}>
              {content}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
