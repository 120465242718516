import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Container, LinearProgress, Typography } from "@material-ui/core";

import AgencyDetailsScapeForm from "../../form/scape/AgencyDetailsScapeForm";
import StudentDetailsScapeForm from "../../form/scape/StudentDetailsScapeForm";
import StudentEducationalDetailsScapeForm from "../../form/scape/StudentEducationalDetails.Form";
import BookingPreferencesScapeForm from "../../form/scape/BookingPreferencesScapeForm";
import { FIELDS } from "../../config/scape";
import { CheckCircleOutline, Save, Send } from "@material-ui/icons";
import OutlinedCard from "../../components/OutlinedCard";
import GlobalContext from "../../context/scape";
import { COLOR_SCHEME, STATUS_MEANING, useStylesScapeForm, isProviderAvailable, blackListEmailValidation } from "../../constants/constants";
import { GetBotBookings, GetZohoBookingsContacts, PostBotBookings } from "../../api/helper";

export default function Form() {
  let { provider, bookingId, isEditable, error, status, setGlobalData } = useContext(GlobalContext);

  /* simple loading */
  const [loading, setLoading] = useState(false);

  /* get details from API and store the array then send appropriate data to corresponding component */
  const [details, setDetails] = useState(FIELDS(undefined, undefined, undefined));

  /* if successfully API hits make it success and show success card else show form */
  const [success, setSuccess] = useState(false);

  /* if objectId present - show text update else save on button. Having objectId means data is already 
    saved in BotBooking collection */
  const [objectId, setObjectId] = useState(null);

  /* update status and send to server accordingly
    if current status i.e status from api is either SAVED or FAILED and you are hitting button on save/update just 
    update it with SAVE and if you hitting button proceed update it with status PENDING - so the Bot can 
    pick PENDING status forms. */
  const [updatedStatus, setUpdatedStatus] = useState("PENDING");

  /* MUI styles */
  const classes = useStylesScapeForm();

  useEffect(() => {
    fetchDataFromApi();
  }, []);

  async function fetchDataFromApi() {
    try {
      setLoading(true);

      const botBookingReponse = await GetBotBookings(bookingId);
      const result = botBookingReponse?.data || null;

      if (!result) {
        // if data not get from /bot-booking call /zoho-bookings-contacts

        try {
          const zohoBookingsContacts = await GetZohoBookingsContacts(bookingId);
          const result = zohoBookingsContacts?.data || null;

          if (result) {
            if (!isProviderAvailable(result.booking.Provider)) {
              setGlobalData((prev) => ({
                ...prev,
                isProviderAval: false,
                isEditable: false,
                error: `Invalid Provider - ${result.booking.Provider} `,
              }));
            }
          }
          let contact = result?.contact ?? undefined;
          let booking = result?.booking ?? undefined;

          if (contact || booking) {
            setDetails(FIELDS(contact, booking, undefined));
          }
        } catch (error) {
          setGlobalData((prev) => ({
            ...prev,
            isEditable: false,
            error: error.response?.data?.error || "Something went wrong - Unable to fetch data from server",
          }));
          setLoading(false);
        }
      }

      if (result) {
        // if data found just process it
        setObjectId(result._id);
        setGlobalData((prev) => ({ ...prev, status: result.status.indexOf("-") ? result.status : "PENDING" }));
        setDetails(FIELDS(undefined, undefined, result));
      }

      setLoading(false);
    } catch (error) {
      setGlobalData((prev) => ({
        ...prev,
        isEditable: false,
        error: error.response?.data?.error || "Something went wrong - Unable to fetch data from server",
      }));
      setLoading(false);
    }
  }

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [error]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {

      // extract all keys
      const KEYS = Object.keys(
        FIELDS(undefined, undefined, undefined).reduce((prev, curr, idx, arr) => {
          Object.assign(prev, curr);
          return prev;
        }, {}),
      );

      // return object contains data - what to send to the server
      const result = KEYS.reduce((prev, key) => {
        if (e.target[key]?.id) {
          Object.assign(prev, { [e.target[key].id]: e.target[key].value });
        }
        if (e.target[key]?.name) {
          Object.assign(prev, { [e.target[key].name]: e.target[key].value });
        }
        return prev;
      }, {});

      // assigning bookingId & provider to result
      Object.assign(result, { bookingId, provider, status: updatedStatus });

      if (blackListEmailValidation(result['student_email_address'])) {
        setGlobalData((prev) => ({ ...prev, error: 'Student Email Address is not valid.', isEditable: false }));
      } else {
        // save-bot-booking
        const response = await PostBotBookings(result);
        setSuccess(true);
        setDetails(FIELDS(undefined, undefined, response.data));
        setObjectId(response.data._id);
        setGlobalData((prev) => ({ ...prev, isEditable: false, status: updatedStatus, error: null }));
      }
      
    } catch (error) {
      setGlobalData((prev) => ({ ...prev, error: "Could not connect to the server" }));
    }
  };

  return loading ? (
    /* horizontal linear progress */
    <Box m={2}>
      <LinearProgress style={{ color: COLOR_SCHEME.PRIMARY }} />
    </Box>
  ) : (
    /* FORM */
    <>
      {/* only on success */}
      {success && (
        <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
          <OutlinedCard
            callback={setSuccess}
            title={`${objectId ? "Data has been saved successfully." : "Data has been sent to the server successfully."}`}
            description={STATUS_MEANING[updatedStatus]}
            icon={<CheckCircleOutline style={{ color: COLOR_SCHEME.GREEN }} fontSize="large" />}
            buttonText="Back"
          />
        </Box>
      )}

      {/* other than success message show form */}
      {!success && (
        <Container maxWidth="md" style={{ padding: "0 2rem 2rem 2rem" }}>
          {/* if data processed by bot don't show buttons */}
          {status === "SUCCESS" && (
            <Box style={{ backgroundColor: COLOR_SCHEME.WARNING }} width="auto" borderRadius="6px" p={1}>
              <Typography style={{ color: COLOR_SCHEME.WHITE }} component="div">
                {`Form already processed by Bot.`}
              </Typography>
            </Box>
          )}

          {/* error messages */}
          {error !== null && (
            <Box border={`1px solid ${COLOR_SCHEME.ERROR}`} style={{ backgroundColor: COLOR_SCHEME.ERROR }} width="auto" borderRadius="6px" p={1}>
              <Typography style={{ color: COLOR_SCHEME.WHITE }} component="div">
                {`Error: ${error}`}
              </Typography>
            </Box>
          )}

          {/* form */}
          <form className={classes.root} autoComplete="off" onSubmit={handleSubmit}>
            {/* form content */}
            <AgencyDetailsScapeForm details={details[0]} />
            <StudentDetailsScapeForm details={details[1]} />
            <StudentEducationalDetailsScapeForm details={details[2]} />
            <BookingPreferencesScapeForm details={details[3]} />

            {/* show buttons only when form is editable */}
            {isEditable && status !== "SUCCESS" && (
              <Box style={{ display: "flex", justifyContent: "space-between", marginTop: "1rem", flexWrap: "wrap", gap: "1rem" }}>
                <Button
                  type="submit"
                  startIcon={<Save />}
                  style={{ flexGrow: 1, backgroundColor: COLOR_SCHEME.PRIMARY, color: COLOR_SCHEME.WHITE }}
                  onClick={() => setUpdatedStatus("SAVED")}
                  variant="contained"
                >{`${objectId ? "Update" : "Save"}`}</Button>
                <Button
                  startIcon={<Send />}
                  type="submit"
                  style={{ flexGrow: 1, backgroundColor: COLOR_SCHEME.GREEN, color: COLOR_SCHEME.WHITE }}
                  variant="contained"
                  onClick={() => setUpdatedStatus("PENDING")}
                >
                  Proceed
                </Button>
              </Box>
            )}
          </form>
        </Container>
      )}
    </>
  );
}
