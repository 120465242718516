import { Box, Typography } from "@material-ui/core";
import React from "react";
import { COLOR_SCHEME } from "../constants/constants";

export default function NotFound({ title, description }) {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
      <Typography variant="h1" style={{ color: COLOR_SCHEME.PRIMARY }}>
        {title}
      </Typography>
      <Typography variant="h6" style={{ color: COLOR_SCHEME.PRIMARY }}>
        {description}
      </Typography>
    </Box>
  );
}
