import { Box, Card, CardActions, CardContent, Button, Typography } from "@material-ui/core";
import * as React from "react";
import { COLOR_SCHEME, COMPONENT_WIDTH } from "../constants/constants";

export default function OutlinedCard({ showButton = true, description = "", callback, title = "", icon = <></>, buttonText = "" }) {
  return (
    <Box sx={{ minWidth: COMPONENT_WIDTH.OUTLINED_CARD_MIN_WIDTH }}>
      <Card variant="outlined">
        <React.Fragment>
          <CardContent>
            <Box>{icon}</Box>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              {title}
            </Typography>
          </CardContent>
          {description && <CardContent>
            <Typography sx={{ fontSize: 12 }} style={{ color: COLOR_SCHEME.PRIMARY }} gutterBottom>
              {`Status: ${description}`}
            </Typography>
          </CardContent>}
          {showButton && (
            <CardActions>
              <Button size="small" variant="outlined" color="primary" onClick={() => callback(false)}>
                {buttonText}
              </Button>
            </CardActions>
          )}
        </React.Fragment>
      </Card>
    </Box>
  );
}
