import TextFieldComponent from "../../components/TextFieldComponent";
import { useContext, useEffect, useState } from "react";
import { AGENCY_DETAILS } from "../../config/scape";
import GlobalContext from "../../context/scape";
import CardComponent from "../../components/CardComponent";

export default function AgencyDetailsScapeForm({ details }) {
    const [agencyData, setAgencyData] = useState({});
    const { isEditable } = useContext(GlobalContext);

    useEffect(() => {
        setAgencyData((prev) => ({
            ...prev,
            ...details
        }))
    }, [details])

    return (
        <CardComponent title="Agency Details" content={
            AGENCY_DETAILS.map((field) => <TextFieldComponent
                disabled={!isEditable}
                key={field.botMapping}
                label={field.label}
                type={field.type}
                required={field.required}
                options={field?.options || []}
                value={agencyData[field.botMapping]}
                botMapping={field.botMapping}
                onChange={(e) => setAgencyData(prev => ({ ...prev, [e.target.id]: e.target.value }))}
            />)
        } />
    )
}