import { makeStyles } from "@material-ui/core/styles";

/* to handle header - if provider available show header else not,
so you need to maintain available providers here */
export const isProviderAvailable = (provider = "") => {
  return ['SCAPE AUSTRALIA', 'SCAPE'].includes(provider.toUpperCase());
};

export const isScapeProvider = (provider = "") => {
    return provider.toUpperCase() === 'SCAPE' || provider.toUpperCase() === 'SCAPE AUSTRALIA'
}

export const blackListEmailValidation = (email = "") => {
    return email.match(/universityliving|yopmail/g);
}

export const useStylesScapeForm = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

export const STATUS_MEANING = {
  PENDING: "Not picked by bot yet, but your response has been added to queue for processing.",
  SAVED: "Form saved, but not submitted for processing. You need to click proceed for futher processing.",
  FAILED: "Failed, something went wrong - either bot error or form error. Please check.",
  SUCCESS: "Successfully processed by bot, your record has been submitted to provider.",
};

export const COLOR_SCHEME = {
  PRIMARY: "#3f51b5",
  SAVED: "rgb(91, 192, 222)",
  SUCCESS: "rgb(92, 184, 92)",
  ERROR: "#f50057",
  FAILED: "#f50057",
  WARNING: "rgb(245, 124, 0)",
  DISABLED: "rgba(0, 0, 0, 0.38)",
  PENDING: "#F7CB73",
  WHITE: "#ffffff",
  GREEN: "green",
};

export const COMPONENT_WIDTH = {
  CUSTOM_CARD_MAX_WIDTH: 345,
  OUTLINED_CARD_MIN_WIDTH: 275,
  FORM_INPUT_WIDTH: 250,
};
