import TextFieldComponent from "../../components/TextFieldComponent";
import SelectComponent from "../../components/SelectComponent";
import { useContext, useEffect, useState } from "react";
import { BOOKING_PREFERENCES } from "../../config/scape";
import GlobalContext from "../../context/scape";
import CardComponent from "../../components/CardComponent";

export default function BookingPreferencesScapeForm({ details }) {
    const [bookingPreferencesData, setBookingPreferencesData] = useState({});
    const { isEditable } = useContext(GlobalContext);

    useEffect(() => {
        setBookingPreferencesData((prev) => ({
            ...prev,
            ...details
        }))
    }, [details])

    return (
        <CardComponent title="Booking Preferences" content={
            BOOKING_PREFERENCES.map((field) => {
                if (field.type === 'select') {
                    return (<SelectComponent
                        disabled={!isEditable}
                        required={field.required}
                        key={field.botMapping}
                        label={field.label}
                        type={field.type}
                        options={field?.options || []}
                        value={field.options.filter(v => v?.toLowerCase() === bookingPreferencesData[field.botMapping]?.toLowerCase())[0] || ''}
                        actualValue={bookingPreferencesData[field.botMapping] || ''}
                        botMapping={field.botMapping}
                        onChange={(e) => setBookingPreferencesData(prev => ({ ...prev, [e.target.name]: e.target.value }))}
                    />)
                }
                return <TextFieldComponent
                    disabled={!isEditable}
                    key={field.botMapping}
                    label={field.label}
                    type={field.type}
                    required={field.required}
                    value={bookingPreferencesData[field.botMapping]}
                    botMapping={field.botMapping}
                    onChange={(e) => setBookingPreferencesData(prev => ({ ...prev, [e.target.id]: e.target.value }))}
                />
            })
        } />
    )
}