const OPTION_YES_NO = ["Yes", "No"];

const SCAPE_STUDENT_COUNTRY = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, Democratic Republic of the",
  "Costa Rica",
  "CÃ´te d'Ivoire",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czechia",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (Democratic People's Republic of)",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom of Great Britain and Northern Ireland",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic of)",
  "Viet Nam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

const SCAPE_STUDENT_NATIONALITY = [
  "Afghan",
  "Albanian",
  "Algerian",
  "American",
  "Andorran",
  "Angolan",
  "Anguillan",
  "Argentine",
  "Armenian",
  "Australian",
  "Austrian",
  "Azerbaijani",
  "Bahamian",
  "Bahraini",
  "Bangladeshi",
  "Barbadian",
  "Belarusian",
  "Belgian",
  "Belizean",
  "Beninese",
  "Bermudian",
  "Bhutanese",
  "Bolivian",
  "Botswanan",
  "Brazilian",
  "British",
  "British Virgin Islander",
  "Bruneian",
  "Bulgarian",
  "Burkinan",
  "Burmese",
  "Burundian",
  "Cambodian",
  "Cameroonian",
  "Canadian",
  "Cape Verdean",
  "Cayman Islander",
  "Central African",
  "Chadian",
  "Chilean",
  "Chinese",
  "Citizen of Antigua and Barbuda",
  "Citizen of Bosnia and Herzegovina",
  "Citizen of Guinea-Bissau",
  "Citizen of Kiribati",
  "Citizen of Seychelles",
  "Citizen of the Dominican Republic",
  "Citizen of Vanuatu",
  "Colombian",
  "Comoran",
  "Congolese (Congo)",
  "Congolese (DRC)",
  "Cook Islander",
  "Costa Rican",
  "Croatian",
  "Cuban",
  "Cymraes",
  "Cymro",
  "Cypriot",
  "Czech",
  "Danish",
  "Djiboutian",
  "Dominican",
  "Dutch",
  "East Timorese",
  "Ecuadorean",
  "Egyptian",
  "Emirati",
  "English",
  "Equatorial Guinean",
  "Eritrean",
  "Estonian",
  "Ethiopian",
  "Faroese",
  "Fijian",
  "Filipino",
  "Finnish",
  "French",
  "Gabonese",
  "Gambian",
  "Georgian",
  "German",
  "Ghanaian",
  "Gibraltarian",
  "Greek",
  "Greenlandic",
  "Grenadian",
  "Guamanian",
  "Guatemalan",
  "Guinean",
  "Guyanese",
  "Haitian",
  "Honduran",
  "Hong Konger",
  "Hungarian",
  "Icelandic",
  "Indian",
  "Indonesian",
  "Iranian",
  "Iraqi",
  "Irish",
  "Israeli",
  "Italian",
  "Ivorian",
  "Jamaican",
  "Japanese",
  "Jordanian",
  "Kazakh",
  "Kenyan",
  "Kittitian",
  "Kosovan",
  "Kuwaiti",
  "Kyrgyz",
  "Lao",
  "Latvian",
  "Lebanese",
  "Liberian",
  "Libyan",
  "Liechtenstein citizen",
  "Lithuanian",
  "Luxembourger",
  "Macanese",
  "Macedonian",
  "Malagasy",
  "Malawian",
  "Malaysian",
  "Maldivian",
  "Malian",
  "Maltese",
  "Marshallese",
  "Martiniquais",
  "Mauritanian",
  "Mauritian",
  "Mexican",
  "Micronesian",
  "Moldovan",
  "Monegasque",
  "Mongolian",
  "Montenegrin",
  "Montserratian",
  "Moroccan",
  "Mosotho",
  "Mozambican",
  "Namibian",
  "Nauruan",
  "Nepalese",
  "New Zealander",
  "Nicaraguan",
  "Nigerian",
  "Nigerien",
  "Niuean",
  "North Korean",
  "Northern Irish",
  "Norwegian",
  "Omani",
  "Pakistani",
  "Palauan",
  "Palestinian",
  "Panamanian",
  "Papua New Guinean",
  "Paraguayan",
  "Peruvian",
  "Pitcairn Islander",
  "Polish",
  "Portuguese",
  "Prydeinig",
  "Puerto Rican",
  "Qatari",
  "Romanian",
  "Russian",
  "Rwandan",
  "Salvadorean",
  "Sammarinese",
  "Samoan",
  "Sao Tomean",
  "Saudi or Saudi Arabian",
  "Scottish",
  "Senegalese",
  "Serbian",
  "Sierra Leonean",
  "Singaporean",
  "Slovak",
  "Slovenian",
  "Solomon Islander",
  "Somali",
  "South African",
  "South Korean",
  "South Sudanese",
  "Spanish",
  "Sri Lankan",
  "St Helenian",
  "St Lucian",
  "Stateless",
  "Sudanese",
  "Surinamese",
  "Swazi",
  "Swedish",
  "Swiss",
  "Syrian",
  "Taiwanese",
  "Tajik",
  "Tanzanian",
  "Thai",
  "Togolese",
  "Tongan",
  "Trinidadian",
  "Tristanian",
  "Tunisian",
  "Turkish",
  "Turkmen",
  "Turks and Caicos Islander",
  "Tuvaluan",
  "Ugandan",
  "Ukrainian",
  "Uruguayan",
  "Uzbek",
  "Vatican citizen",
  "Venezuelan",
  "Vietnamese",
  "Vincentian",
  "Wallisian",
  "Welsh",
  "Yemeni",
  "Zambian",
  "Zimbabwean",
];

const SCAPE_STUDENT_YEAR_OF_STUDY = ["1st Year", "2nd Year", "3rd Year", "4th Year", "Not Applicable/Unknown"];

const SCAPE_STUDENT_COURSE_LEVEL = [
  "Undergraduate",
  "Postgraduate",
  "PhD",
  "Language Course",
  "Diploma",
  "Certificate",
  "Foundation",
  "English Language",
];

const SCAPE_STUDENT_GENDER = ["Male", "Female", "Neutral", "Unknown", "Other"];

const PREFERRED_ROOM_TYPES = [
  "1 Bedroom Apartment",
  "2 Bedroom Apartment",
  "3 Bedroom Apartment",
  "3 X Twin Apartment",
  "4 Bedroom Apartment",
  "4 Bedroom Saver",
  "4 X Twin Apartment",
  "4 X Twin Bunk Apartment",
  "5 Bedroom Apartment",
  "5 Twin Mix Saver",
  "5 X Twin Apartment",
  "6 Bedroom Apartment",
  "7 Bedroom Apartment",
  "8 Bedroom Apartment",
  "8 X Twin Apartment",
  "9 Bedroom Apartment",
  "Corner Studio Apartment",
  "Large 2 Bedroom Studio",
  "Large Studio Apartment",
  "Large Twin Studio",
  "Medium 2 Bedroom Studio",
  "Medium Studio Apartment",
  "Medium Twin Studio",
  "Studio Apartment",
  "Twin Studio",
];

const PREFERRED_LOCATIONS = [
  "Scape Abercrombie",
  "Scape Adelaide Central",
  "Scape Berkeley 1",
  "Scape Berkeley 2",
  "Boundary x Scape",
  "Broadway x Scape",
  "Scape Carlton",
  "Scape Cleveland",
  "Scape Darling House",
  "Scape Darling Square",
  "Scape Darlington",
  "Scape Franklin",
  "Scape Glebe",
  "Scape La Trobe",
  "Scape Lincoln College",
  "Scape Melbourne Central",
  "Scape Merivale",
  "Mountain x Scape",
  "Scape Peel",
  "Scape Quay",
  "Scape Queensberry",
  "Scape Redfern",
  "Scape Regent",
  "Scape South Bank",
  "Scape St Lucia",
  "Scape Swanston",
  "Scape Sydney Central",
  "Scape Toowong",
  "Scape Tribune",
  "Scape at University of Adelaide",
  "Scape at University of Sydney",
  "Scape Waymouth",
];

export {
  SCAPE_STUDENT_NATIONALITY,
  SCAPE_STUDENT_COUNTRY,
  OPTION_YES_NO,
  SCAPE_STUDENT_GENDER,
  SCAPE_STUDENT_COURSE_LEVEL,
  SCAPE_STUDENT_YEAR_OF_STUDY,
  PREFERRED_LOCATIONS,
  PREFERRED_ROOM_TYPES,
};
