import TextFieldComponent from "../../components/TextFieldComponent";
import SelectComponent from "../../components/SelectComponent";
import { useContext, useEffect, useState } from "react";
import { STUDENT_DETAILS } from "../../config/scape";
import GlobalContext from "../../context/scape";
import CardComponent from "../../components/CardComponent";

export default function StudentDetailsScapeForm({ details }) {
    const [studentDetailsData, setStudentDetailsData] = useState({});
    const { isEditable } = useContext(GlobalContext);

    useEffect(() => {
        setStudentDetailsData((prev) => ({
            ...prev,
            ...details
        }))
    }, [details])

    return (
        <CardComponent title="Student Details" content={
            STUDENT_DETAILS.map((field) => {
                if (field.type === 'select') {
                    return (<SelectComponent
                        disabled={!isEditable}
                        key={field.botMapping}
                        required={field.required}
                        label={field.label}
                        type={field.type}
                        options={field?.options || []}
                        value={field.options.filter(v => v?.toLowerCase() === studentDetailsData[field.botMapping]?.toLowerCase())[0] || ''}
                        actualValue={studentDetailsData[field.botMapping] || ''}
                        botMapping={field.botMapping}
                        onChange={(e) => setStudentDetailsData(prev => ({ ...prev, [e.target.name]: e.target.value }))}
                    />)
                }
                return (<TextFieldComponent
                    disabled={!isEditable}
                    key={field.botMapping}
                    label={field.label}
                    type={field.type}
                    required={field.required}
                    value={studentDetailsData[field.botMapping]}
                    botMapping={field.botMapping}
                    onChange={(e) => setStudentDetailsData(prev => ({ ...prev, [e.target.id]: e.target.value }))}
                />)
            })
        } />
    )
}