import { FormControl, InputLabel, MenuItem, Select, FormHelperText } from "@material-ui/core";
import { COMPONENT_WIDTH, COLOR_SCHEME } from "../constants/constants";

export default function SelectComponent({ disabled, key, botMapping, label, required, type, options, value, onChange, actualValue }) {
  return (
    <FormControl variant="outlined" required={required} style={{ width: COMPONENT_WIDTH.FORM_INPUT_WIDTH, margin: "8px" }} key={key}>
      <InputLabel id="select-autowidth-label">{label}</InputLabel>
      <Select
        key={key}
        disabled={disabled}
        labelId="select-autowidth-label"
        name={botMapping}
        value={value}
        label={label}
        autoWidth
        required={required}
        onChange={onChange}
      >
        <MenuItem value="" disabled selected>
          {label}
        </MenuItem>
        {options.map((item) => (
          <MenuItem value={item}>{item}</MenuItem>
        ))}
      </Select>
      {actualValue && !options.includes(actualValue) && (
        <FormHelperText style={{ color: disabled ? COLOR_SCHEME.DISABLED : COLOR_SCHEME.WARNING }}>{`Actual value: ${actualValue}`}</FormHelperText>
      )}
    </FormControl>
  );
}
