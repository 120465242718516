import { TextField, FormControl } from "@material-ui/core";
import moment from "moment";
import { COMPONENT_WIDTH } from "../constants/constants";

export default function TextFieldComponent({ disabled, key, botMapping, label, required, type, value, onChange }) {
  return (
    <FormControl>
      <TextField
        style={{ width: COMPONENT_WIDTH.FORM_INPUT_WIDTH }}
        key={key}
        id={botMapping}
        label={label}
        required={required}
        type={type}
        value={type === "date" ? moment(value).format("yyyy-MM-DD") : value}
        onChange={onChange}
        disabled={disabled}
        variant="outlined"
        overflow
      />
    </FormControl>
  );
}
