import TextFieldComponent from "../../components/TextFieldComponent";
import SelectComponent from "../../components/SelectComponent";
import { useContext, useEffect, useState } from "react";
import { STUDENT_EDUCATIONAL } from "../../config/scape";
import GlobalContext from "../../context/scape";
import CardComponent from "../../components/CardComponent";

export default function StudentEducationalDetailsScapeForm({ details }) {
    const [studentEducationalDetailsData, setStudentEducationalDetailsData] = useState({});
    const { isEditable } = useContext(GlobalContext);
    
    useEffect(() => {
        setStudentEducationalDetailsData((prev) => ({
            ...prev,
            ...details
        }))
    }, [details])

    return (
        <CardComponent title="Student Educational Details" content={
            STUDENT_EDUCATIONAL.map((field) => {
                if (field.type === 'select') {
                    return (<SelectComponent 
                        disabled={!isEditable} 
                        key={field.botMapping} 
                        required={field.required} 
                        label={field.label} 
                        type={field.type} 
                        options={field?.options || []} 
                        value={field.options.filter(v => v?.toLowerCase() === studentEducationalDetailsData[field.botMapping]?.toLowerCase())[0] || ''} 
                        botMapping={field.botMapping} 
                        actualValue={studentEducationalDetailsData[field.botMapping] || ''}
                        onChange={(e) => setStudentEducationalDetailsData(prev => ({ ...prev, [e.target.name]: e.target.value }))} 
                        />)
                }
                return (<TextFieldComponent 
                    disabled={!isEditable} 
                    key={field.botMapping} 
                    label={field.label} 
                    type={field.type} 
                    required={field.required} 
                    value={studentEducationalDetailsData[field.botMapping]} 
                    botMapping={field.botMapping} 
                    onChange={(e) => setStudentEducationalDetailsData(prev => ({ ...prev, [e.target.id]: e.target.value }))} 
                    />)
            })
        } />
    )
}