import { Box, Button, Card, Tooltip, Typography } from "@material-ui/core";
import ScapeForm from "./scape/Form";
import React, { useContext } from "react";
import OutlinedCard from "../components/OutlinedCard";
import { PriorityHigh, FiberManualRecordSharp, InfoRounded } from "@material-ui/icons";
import GlobalContext from "../context/scape";
import { Edit, Visibility } from "@material-ui/icons";
import { isProviderAvailable, COLOR_SCHEME, STATUS_MEANING, isScapeProvider } from "../constants/constants";
import "./index.css";

export default function Index() {
  const { provider, isEditable, status, setGlobalData, isProviderAval } = useContext(GlobalContext);

  /* return provider form according to provider else No form available Card */
  const Component = () => {
    if (isScapeProvider(provider)) {
      return <ScapeForm />;
    } else {
      return (
        <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
          <OutlinedCard
            showButton={false}
            title={`No form available for this provider - ${provider}.`}
            icon={<PriorityHigh style={{ color: COLOR_SCHEME.ERROR || "red" }} fontSize="large" />}
          />
        </Box>
      );
    }
  };

  const header = () => {
    return (
      <Box p={2}>
        <Card
          style={{
            backgroundColor: COLOR_SCHEME.PRIMARY,
            color: COLOR_SCHEME.WHITE,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          elevation
        >
          <Box display="flex" alignItems="center" justifyContent="space-around">
            <Typography component="div" style={{ padding: "16px" }}>
              {`Provider: ${provider}`}
            </Typography>

            <FiberManualRecordSharp className="statusDot" style={{ color: COLOR_SCHEME[status] }} fontSize="small" />

            <Typography component="div" style={{ padding: "16px" }}>
              {`Status: ${status}`}
            </Typography>

            <Tooltip className="info" title={STATUS_MEANING[status]} arrow>
              <InfoRounded fontSize="small" />
            </Tooltip>
          </Box>

          {/* status SUCCESS means form processed by bot, so no need to edit form again once if it's processed */}
          {status !== "SUCCESS" && isProviderAval && (
            <Box p={1}>
              <Button
                style={{ color: COLOR_SCHEME.WHITE, borderColor: COLOR_SCHEME.WHITE }}
                startIcon={!isEditable ? <Edit /> : <Visibility />}
                onClick={() => setGlobalData((prev) => ({ ...prev, isEditable: !isEditable }))}
                variant="outlined"
              >{`${!isEditable ? "Edit" : "Read Only"}`}</Button>
            </Box>
          )}
        </Card>
      </Box>
    );
  };

  return (
    <>
      {/* show header is provider is available in AVAILABLE_PROVIDERS */}
      {isProviderAvailable(provider) && header()}

      {/* form */}
      {Component()}
    </>
  );
}
