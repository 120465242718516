import {
  PREFERRED_LOCATIONS,
  PREFERRED_ROOM_TYPES,
  OPTION_YES_NO,
  SCAPE_STUDENT_COUNTRY,
  SCAPE_STUDENT_GENDER,
  SCAPE_STUDENT_NATIONALITY,
  SCAPE_STUDENT_COURSE_LEVEL,
  SCAPE_STUDENT_YEAR_OF_STUDY,
} from "../../constants/scape/scape";
import moment from "moment";

export const FIELDS = (contact, booking, botBooking) => [
  {
    counsellor_name: botBooking?.counsellor_name || booking?.Owner?.name || "",
    counsellor_email: botBooking?.counsellor_email || "hello@universityliving.com",
    agency_name: botBooking?.agency_name || "University Living",
    agency_city: botBooking?.agency_city || "Noida",
    agency_country_region: botBooking?.agency_country_region || "India",
    agency_branch: botBooking?.agency_branch || "India",
    agency_booking_code: botBooking?.agency_booking_code || "UNIVERSITYLIVING",
    comments: botBooking?.comments || "",
  },
  {
    first_name: botBooking?.first_name || contact?.First_Name || "",
    surname: botBooking?.surname || contact?.Last_Name || "",
    date_of_birth: botBooking?.date_of_birth || contact?.Date_of_Birth || moment().format("yyyy-MM-DD"),
    nationality: botBooking?.nationality || contact?.nationality || "",
    student_email_address: botBooking?.student_email_address || contact?.Email || "",
    student_phone_number: botBooking?.student_phone_number || contact?.Phone || "",
    student_current_street_address: botBooking?.student_current_street_address || contact?.address || "",
    student_current_city: botBooking?.student_current_city || contact?.city || "",
    student_current_state_province: botBooking?.student_current_state_province || contact?.User_State_Province || "",
    student_current_postcode_zip_code: botBooking?.student_current_postcode_zip_code || contact?.Pincode || "",
    student_current_country_region: botBooking?.student_current_country_region || contact?.country || "",
    is_student_currently_in_australia:
      botBooking?.is_student_currently_in_australia || contact?.country?.toLowerCase() === "australia" ? "Yes" : "No" || "",
    gender: botBooking?.gender || contact?.gender || "",
  },
  {
    education_institution: botBooking?.education_institution || (booking?.Account_Name ? booking?.Account_Name?.name : "") || "",
    course_level: botBooking?.course_level || "",
    year_of_study: botBooking?.year_of_study || yearOfStudyMapping[contact?.Year_of_Study] || contact?.Year_of_Study || "",
    program_or_course: botBooking?.program_or_course || booking?.universityCourse || "",
    student_id: botBooking?.student_id || "NA",
  },
  {
    preferred_location: botBooking?.preferred_location || booking?.Property_Name || "",
    preferred_room_type: botBooking?.preferred_room_type || booking?.Room_Type || "",
    preferred_weekly_rent: botBooking?.preferred_weekly_rent || booking?.price || "",
    start_date: botBooking?.start_date || booking?.CheckIn_Date || moment().format("yyyy-MM-DD"),
    end_date: botBooking?.end_date || booking?.CheckOut_Date || moment().format("yyyy-MM-DD"),
    disability_access_required: botBooking?.disability_access_required || "",
    living_preferences: botBooking?.living_preferences || "",
  },
];

export const AGENCY_DETAILS = [
  { label: "Counsellor Name", required: true, type: "text", botMapping: "counsellor_name" },
  { label: "Counsellor Email", required: true, type: "email", botMapping: "counsellor_email" },
  { label: "Agency Name", required: true, type: "text", botMapping: "agency_name" },
  { label: "Agency City", required: true, type: "text", botMapping: "agency_city" },
  { label: "Agency Country", required: true, type: "text", botMapping: "agency_country_region" },
  { label: "Agency Branch", required: true, type: "text", botMapping: "agency_branch" },
  { label: "Agency Booking Code", required: true, type: "text", botMapping: "agency_booking_code" },
  { label: "Comments", required: false, type: "text", botMapping: "comments" },
];

export const BOOKING_PREFERENCES = [
  { label: "Preferred Location", required: true, type: "select", botMapping: "preferred_location", options: PREFERRED_LOCATIONS },
  { label: "Preferred Room Type", required: true, type: "select", botMapping: "preferred_room_type", options: PREFERRED_ROOM_TYPES },
  { label: "Preferred Weekly Rent", required: true, type: "text", botMapping: "preferred_weekly_rent" },
  { label: "Start Date", required: true, type: "date", botMapping: "start_date" },
  { label: "End Date", required: true, type: "date", botMapping: "end_date" },
  { label: "Disability Access Required?", required: true, type: "select", botMapping: "disability_access_required", options: OPTION_YES_NO },
  { label: "Living Preferences", required: false, type: "text", botMapping: "living_preferences" },
];

export const STUDENT_DETAILS = [
  { label: "First Name", required: true, type: "text", botMapping: "first_name" },
  { label: "Surname", required: true, type: "text", botMapping: "surname" },
  { label: "Date of Birth", required: true, type: "date", botMapping: "date_of_birth" },
  { label: "Nationality", required: true, type: "select", botMapping: "nationality", options: SCAPE_STUDENT_NATIONALITY },
  { label: "Student Email", required: true, type: "email", botMapping: "student_email_address" },
  { label: "Student Phone", required: true, type: "text", botMapping: "student_phone_number" },
  { label: "Student Address", required: true, type: "text", botMapping: "student_current_street_address" },
  { label: "Student City", required: true, type: "text", botMapping: "student_current_city" },
  { label: "Student State", required: true, type: "text", botMapping: "student_current_state_province" },
  { label: "Student ZIP Code", required: true, type: "text", botMapping: "student_current_postcode_zip_code" },
  { label: "Student Country", required: true, type: "select", botMapping: "student_current_country_region", options: SCAPE_STUDENT_COUNTRY },
  {
    label: "Student Currently In Australia?",
    required: true,
    type: "select",
    botMapping: "is_student_currently_in_australia",
    options: OPTION_YES_NO,
  },
  { label: "Gender", required: true, type: "select", botMapping: "gender", options: SCAPE_STUDENT_GENDER },
];

export const STUDENT_EDUCATIONAL = [
  { label: "Education Institution", required: false, type: "text", botMapping: "education_institution" },
  { label: "Course Level", required: true, type: "select", botMapping: "course_level", options: SCAPE_STUDENT_COURSE_LEVEL },
  { label: "Year of Study", required: true, type: "select", botMapping: "year_of_study", options: SCAPE_STUDENT_YEAR_OF_STUDY },
  { label: "Program or Course", required: true, type: "text", botMapping: "program_or_course" },
  { label: "Student ID", required: true, type: "text", botMapping: "student_id" },
];

const yearOfStudyMapping = {
  firstYear: "1st Year",
  secondYear: "2nd Year",
  thirdYear: "3rd Year",
  fourthYear: "4th Year",
};
